import React from 'react'
import { Helmet } from 'react-helmet'

import { Layout, HighestRatesTable, AffiliateDisclosure, NonCustodialCard } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Pane } from 'evergreen-ui';
import { SIGN_UP_BONUS_HTML, currentMonth, currentMonthShort } from '../utils/constants';
import { allRatesUS, stabelcoinRatesUS, nonStabelcoinRatesUS, allRatesInt, stabelcoinRatesInt, nonStabelcoinRatesInt } from '../data/ratesTableInfo';
import useLocation from '../utils/useLocation';


/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/

const HighestRatesPage = ({ data, location }) => {
    const [locationParam, setLocationParam] = useLocation();
    const { pathname } = location;
    const splitPathname = pathname.split('/');
    const ratesSubdirectory = `/${splitPathname[1]}/`;
    const isInternational = locationParam === 'international';
    let rates = isInternational ? allRatesInt : allRatesUS;
    let title = `Highest Crypto Interest Rates`;
    let excerpt = `Leaderboard of the highest crypto interest rates to earn yield in CeFi. Top APR / APY rates of ${currentMonth} 2024, from the best crypto interest accounts.`;
    let description = `Leaderboard of the highest crypto interest rates to earn yield in CeFi. Top APR / APY rates of ${currentMonth} 2024, from the best crypto interest accounts.`;
    let image1 = 'https://s2.coinmarketcap.com/static/img/coins/200x200/1.png';
    let image2 = 'https://s2.coinmarketcap.com/static/img/coins/200x200/1027.png';
    let image3 = 'https://s2.coinmarketcap.com/static/img/coins/200x200/3408.png';
    let metaImage = 'https://res.cloudinary.com/dtujxzpfy/image/upload/b_rgb:fff,c_mpad,w_540/v1650251081/blog-images/highest-crypto-rates-featured-image.png';

    if (splitPathname[2]) {
        if (splitPathname[2] === 'stablecoins') {
            rates = isInternational ? stabelcoinRatesInt : stabelcoinRatesUS;
            title = `Highest Stablecoin Interest Rates`;
            excerpt = `Leaderboard of the highest stablecoin interest rates to earn yield in CeFi. Top APR / APY rates of ${currentMonth} 2024, from the best stablecoin interest accounts.`;
            description = `Leaderboard of the highest stablecoin interest rates to earn yield in CeFi. Top APR / APY rates of ${currentMonth} 2024, from the best stablecoin interest accounts.`;
            image1 = 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png';
            image2 = 'https://s2.coinmarketcap.com/static/img/coins/200x200/4943.png';
            metaImage = 'https://res.cloudinary.com/dtujxzpfy/image/upload/b_rgb:fff,c_mpad,w_540/v1650215290/blog-images/highest-stablecoin-rates-featured-image.png';
        } else {
            rates = isInternational ? nonStabelcoinRatesInt : nonStabelcoinRatesUS;
            title = `Highest Cryptocurrency Interest Rates`;
            excerpt = `Leaderboard of the highest cryptocurrency (non-stablecoin) interest rates to earn yield. Top APR / APY rates of ${currentMonth} 2024, from the best CeFi platforms.`;
            description = `Leaderboard of the highest cryptocurrency (non-stablecoin) interest rates to earn yield. Top APR / APY rates of ${currentMonth} 2024, from the best CeFi platforms.`;
            image3 = 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png';
            metaImage = 'https://res.cloudinary.com/dtujxzpfy/image/upload/b_rgb:fff,c_mpad,w_540/v1650251063/blog-images/highest-cryptocurrency-rates-featured-image.png';
        }
    }

    const page = { meta_title: title + ` of ${currentMonthShort} 2024`, title, excerpt, description };
    const newData = { ghostPage: page };

    return (
        <>
            <MetaData
                data={newData}
                location={location}
                type="website"
                image={metaImage}
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout location={location}>
                <div className="container">
                    <article className="content">
                        {/* { page.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ page.feature_image } alt={`Logo - ${title}`} />
                            </figure> : null } */}
                        <Pane className="content-title image-heading-container">
                            <img className="crypto-icon" src={image1} alt={`${title} Featured Image`} width={36} height={36} />
                            <img className="crypto-icon stacked-crypto-icons" src={image2} alt={`${title} Featured Image`} width={36} height={36} />
                            <img className="crypto-icon stacked-crypto-icons" src={image3} alt={`${title} Featured Image`} width={36} height={36} />
                            <h1 className="image-heading">{title} of {currentMonthShort} 2024</h1>
                        </Pane>
                        <Pane paddingBottom={12}>
                            <section className="content-body load-external-scripts post-card-excerpt">{excerpt}</section>
                        </Pane>
                        <NonCustodialCard />
                        <HighestRatesTable rates={rates} ratesSubdirectory={ratesSubdirectory} pathname={pathname} location={locationParam} onLocationChange={setLocationParam} />
                        <AffiliateDisclosure />
                        <section
                            className="post-full-content content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: SIGN_UP_BONUS_HTML }}
                        />
                    </article>
                </div>
            </Layout>
        </>
    )
}

export default HighestRatesPage;
